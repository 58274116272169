import generic from './generic';
import cover from '../images/covers/paris.jpg';

export default {
    ...generic,
    slug: 'moscow',
    cover,
    collections: [
      "review", 
      "unusual",  
    ],
    cityNames: ['Париж', 'Париже'],
    reviews: [
      // TODO: fakes
      // {
      //   avatar: alexey,
      //   name: "Алексей",
      //   text: "Круто, особенно понравился людоед, сделали кучу прикольных фоток!",
      //   product: {
      //     name: "Неформальные достопримечательности Петербурга: Площадь Востания",
      //     link: "https://surprizeme.ru/ru/saint-petersburg/unusual/neformalnye-dostoprimechatelnosti-peterburga/"
      //   }
      // },
      // {
      //   avatar: irina,
      //   name: "Ирина",
      //   text: "Нестандартная и необычная экскурсия. Бары действительно нужно искать, но это того стоило. Было весело!",
      //   product: {
      //     name: "Культовые бары Петербурга (18+)",
      //     link: "https://surprizeme.ru/ru/saint-petersburg/unusual/neformalnye-bary-peterburga/"
      //   }
      // }
    ]   
};